import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import MentorForm from '../components/Forms/MentorForm';

const MentorSignupPage = () => (
  <Layout>
    <SEO
      title='Home'
      keywords={[`fathering.me`, `fatherlessness`, `new father`, `unplanned`]}
    />
    <MentorForm />
  </Layout>
);

export default MentorSignupPage;
